import PropTypes from 'prop-types';
import React, { useContext, useState, useCallback, useMemo } from 'react';

const SiteContext = React.createContext(null);

export const useSite = () => useContext(SiteContext);

export const useSections = () => {
    const { sections = {} } = useSite();
    return sections;
};

export const useOpenSections = () => {
    const { openSections = {} } = useSite();
    return Object.keys(openSections).reduce((acc, key) => {
        if (openSections[key] === true) return true;
        return acc;
    }, false);
};

export const useCloseSections = () => {
    const { closeSections = {} } = useSite();
    return closeSections;
};

export const useOpenSection = (name) => {
    const { openSections = {} } = useSite();
    return openSections[name] || null;
};

export const useSetOpenSection = () => {
    const { setOpenSection } = useSite();
    return setOpenSection || null;
};

const propTypes = {
    sections: PropTypes.objectOf(PropTypes.object), // eslint-disable-line react/forbid-prop-types
    children: PropTypes.node.isRequired,
};

const defaultProps = {
    sections: {},
};

export const SiteProvider = ({ sections, children }) => {
    const [openSections, setOpenSectionState] = useState({
        ...Object.keys(sections).map((sec) => ({ [sec]: false })),
    });

    const setOpenSection = useCallback(
        (name, value) => {
            setOpenSectionState({
                ...Object.keys(sections).map((sec) => ({ [sec]: false })),
                [name]: value,
            });
        },
        [setOpenSectionState],
    );

    const closeSections = useCallback(() => {
        setOpenSectionState({
            ...Object.keys(sections).map((sec) => ({ [sec]: false })),
        });
    }, [setOpenSectionState]);

    const value = useMemo(
        () => ({ sections, openSections, setOpenSection, closeSections }),
        [sections, openSections, setOpenSection, closeSections],
    );

    return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>;
};

SiteProvider.propTypes = propTypes;
SiteProvider.defaultProps = defaultProps;

export default SiteContext;
