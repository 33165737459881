/* eslint-disable jsx-a11y/control-has-associated-label */

/* eslint-disable react/no-danger */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import styles from '../../styles/partials/links.module.scss';

const propTypes = {
    items: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line react/forbid-prop-types
    className: PropTypes.string,
    children: PropTypes.node,
};

const defaultProps = {
    items: [],
    className: null,
    children: null,
};

const Links = ({ items, className, children }) => {
    const elements = useMemo(
        () =>
            (items || [])
                .map((it, i) => {
                    const { label = null, url = null, source = null } = it || {};
                    if (label !== null && url !== null) {
                        return (
                            <>
                                <br />
                                {i > 0 ? <br /> : null}
                                <a href={url} target="_blank" rel="noopener noreferrer">
                                    {label}
                                </a>
                                {source !== null ? (
                                    <>
                                        <br />
                                        <small>{source}</small>
                                    </>
                                ) : null}
                            </>
                        );
                    }
                    return null;
                })
                .filter((it) => it !== null),
        [items],
    );
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {elements}
            {children}
        </div>
    );
};

Links.propTypes = propTypes;
Links.defaultProps = defaultProps;

export default Links;
