/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import get from 'lodash/get';

import * as AppPropTypes from '../../lib/PropTypes';
import { pascalCase } from '../../lib/utils';
import * as BlockComponents from './index';

import styles from '../../styles/blocks/blocks.module.scss';

const propTypes = {
    blocks: AppPropTypes.blocks,
    components: AppPropTypes.components,
    className: PropTypes.string,
    blockClassName: PropTypes.string,
};

const defaultProps = {
    blocks: [],
    components: BlockComponents,
    className: null,
    blockClassName: null,
};

const Blocks = ({ blocks, components, className, blockClassName }) => (
    <div
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
    >
        {blocks.map((block, index) => {
            const pascalType = pascalCase(block.type);
            const BlockComponent = components[pascalType] || null;
            const currentClassName = block.className || null;

            return BlockComponent !== null ? (
                <div
                    key={`block-${block.id}-${index + 1}`}
                    className={classNames({
                        [styles.block]: true,
                        [blockClassName]: blockClassName !== null,
                        [currentClassName]: currentClassName !== null,
                    })}
                >
                    <BlockComponent id={block.id || index} {...block} />
                </div>
            ) : (
                <p>No block for you {pascalCase(block.type)}</p>
            );
        })}
    </div>
);

Blocks.propTypes = propTypes;
Blocks.defaultProps = defaultProps;

export default Blocks;
