import JJ from '../img/JJ.jpg';
import Thorben from '../img/Thorben.jpg';
import TopLeft from '../img/Top-Left.jpg';

const { REACT_APP_VARIABLE } = process.env;

export default {
    locale: 'en',
    messages: {
        en: {
            'section.metstrat': 'MetStrat',
            'section.campaigns': 'Campaigns',
            'section.contact': 'Contact us',
        },
    },
    routes: {
        home: '/',
    },
    pages: {
        home: {
            title: 'MetStrat',
            description:
                'Research and campaigning in support of decent work and against predatory practices in a changing economy.',
            sections: ['metstrat', 'campaigns', 'contact'],
            footer: {
                email: 'info@metstrat.ca',
                phone: '001 647 409 8997',
            },
        },
    },
    sections: {
        metstrat: {
            tagline: 'We build equitable urban futures.',
            images: [TopLeft, null],
            blocks: [
                {
                    type: 'text',
                    body: 'Metstrat provides research and campaign strategy to build equitable urban futures in a changing economy.',
                },
                {
                    type: 'columns',
                    items: [
                        {
                            image: JJ,
                            name: 'JJ Fueser',
                            email: 'jj@metstrat.ca',
                            linkedin: 'https://www.linkedin.com/in/jj-fueser-2a8bba91/',
                            body: 'JJ has nearly 20 years of experience as a corporate researcher and strategic campaigner. She has helped workers build powerful networks of allies in engaging corporations, developers, and governments, and has worked with investors and stakeholders to encourage publicly traded corporations to become more sustainable, transparent, and accountable. JJ received her B.Sc. from the University of Toronto and her M.Sc. from Yale University in social science.',
                        },
                        {
                            image: Thorben,
                            name: 'Thorben Wieditz',
                            email: 'thorben@metstrat.ca',
                            linkedin: 'https://www.linkedin.com/in/thorben-wieditz-89373820b/',
                            body: 'Thorben is an effective communicator, policy and research analyst and campaigner. Over the years, he has worked on a variety of successful worker, community, and municipal campaigns to protect public assets, public infrastructure, and unionized workplaces. Thorben is trained as an urban geographer and planner at Toronto’s York University and TU University of Dortmund, Germany.',
                        },
                    ],
                },
                {
                    type: 'text',
                    body: 'MetStrat leads and supports comprehensive campaigning in the public interest. We protect public services from disinvestment, encourage sustainable land use, push for ethical investment practices, and help win fair collective agreements. In recent years, we have focused on the ways in which big tech giants are remaking democratic societies in the private interest.',
                },
                {
                    type: 'text',
                    subtitle: 'What we do',
                    body: 'Strategy and planning<br/>Research and corporate analysis<br/>Outreach and coalition building</br/>Communications',
                },
                {
                    type: 'text',
                    subtitle: 'How we work',
                    body: 'We network experienced and aligned partners to create the ideal campaign team. In our work with organizations, we incorporate training and capacity building whenever possible.',
                },
                {
                    type: 'text',
                    subtitle: 'Who we’ve worked with',
                    body: 'We’re proud to have supported the important work of numerous aligned organizations and campaigns, including: ',
                    items: [
                        { type: 'subtitle', text: 'Organizations' },
                        {
                            type: 'bullets',
                            items: [
                                'Jobs to Move America',
                                'Toronto Community Benefits Network',
                                'Society of Professional Engineers and Associates',
                                'Amalgamated Transit Union Local 113',
                                'Ontario Nurses Association',
                                'Land + Planning',
                                'SHARE',
                                'Unifor',
                                'Canadian Union of Postal Workers',
                                'Public Benefit Technologies Corporation',
                            ],
                        },
                        { type: 'subtitle', text: 'Multi-stakeholder campaigns' },
                        {
                            type: 'bullets',
                            items: [
                                'Ridefair Toronto',
                                'Fairbnb Canada',
                                '#Blocksidewalk',
                                'Build the Park',
                            ],
                        },
                    ],
                },
            ],
        },
        campaigns: {
            tagline: 'We lead and support successful public interest campaigns.',
            images: [null, null],
            blocks: [
                {
                    withBottomMargin: true,
                    type: 'text',
                    subtitle: 'Supporting community benefits',
                    body: 'Governments spend billions on infrastructure development. We help organizations ensure that public money is spent in ways that benefit and include communities most affected, and support good, sustainable jobs.',
                    links: [
                        {
                            label: 'Winnipeg bus manufacturer New Flyer accused of discriminatory practices in U.S. plants',
                            url: 'https://www.cbc.ca/news/canada/manitoba/new-flyer-racism-accusations-1.6238081',
                            source: 'CBC News',
                        },
                        {
                            label: 'Labor leaders hail bus maker pact to hire more women, minority workers',
                            url: 'https://www.washingtonpost.com/transportation/2022/05/26/new-flyer-benefits-bus-equity/',
                            source: 'The Washington Post',
                        },
                        {
                            label: 'Province does about-face, commits to hiring targets for under-represented groups on new transit projects, following Star reporting',
                            url: 'https://www.thestar.com/news/gta/2022/03/03/province-does-about-face-keeps-hiring-targets-for-under-represented-groups-on-new-transit-projects-following-star-reporting.html',
                            source: 'Toronto Star',
                        },
                    ],
                },
                {
                    withBottomMargin: true,
                    type: 'text',
                    subtitle: 'Supporting union renewal',
                    body: 'An important part of our work involves supporting unions when they need it most, whether it be a challenging bargaining fight or an ambitious growth plan. We are here to help, providing sustained support or targeted interventions.',
                    links: [
                        {
                            label: 'Candu workers hit the picket lines',
                            url: 'https://www.mississauga.com/news-story/3126865-candu-workers-hit-the-picket-lines/',
                            source: 'Mississauga.com',
                        },
                        {
                            label: 'Workers scrambling after company gives just 2 days notice to return to in-person work full-time',
                            url: 'https://www.cbc.ca/news/canada/toronto/candu-workers-given-2-working-days-notice-to-come-back-1.6480855',
                            source: 'CBC News',
                        },
                        {
                            label: 'SPEA Ratifies Collective Agreements With SNC-Lavalin’s Candu Energy',
                            url: 'https://www.globenewswire.com/news-release/2022/06/14/2462573/0/en/SPEA-Ratifies-Collective-Agreements-With-SNC-Lavalin-s-Candu-Energy.html',
                            source: 'GlobeNewswire',
                        },
                    ],
                },
                {
                    withBottomMargin: true,
                    type: 'text',
                    subtitle: 'Supporting community-led organizing',
                    body: 'We help community groups with research, strategy, communications, and political organizing to protect valuable public assets.',
                    links: [
                        {
                            label: 'Metrolinx on collision course with residents, council over building part of Ontario Line above ground',
                            url: 'https://www.cbc.ca/news/canada/toronto/metrolinx-ontario-line-best-route-above-ground-through-leslieville-1.5903885',
                            source: 'CBC News',
                        },
                        {
                            label: "Plan to increase trains 10-fold through east Toronto will harm residents' health, says report",
                            url: 'https://www.cbc.ca/news/canada/toronto/mextrolinx-ontario-line-trains-increase-1.6233061',
                            source: 'CBC News',
                        },
                        {
                            label: 'Metrolinx decision to destroy Don Valley park land shrouded in secrecy',
                            url: 'https://www.thestar.com/opinion/contributors/2022/02/23/metrolinx-decision-to-destroy-don-valley-park-land-shrouded-in-secrecy.html',
                            source: 'Toronto Star',
                        },
                        {
                            label: "Unused Don Valley tracks could be Toronto's answer to NYC's High Line Park, group says",
                            url: 'https://www.cbc.ca/news/canada/toronto/unused-don-valley-tracks-could-be-toronto-s-answer-to-nyc-s-high-line-park-group-says-1.6032516',
                            source: 'CBC News',
                        },
                    ],
                },
                {
                    withBottomMargin: true,
                    type: 'text',
                    subtitle: 'Supporting Fair Governance',
                    body: 'Cities around the world are tasked with mitigating the false promises and negative impacts of big tech firms like Uber and Airbnb. In municipalities across Canada, we build campaigns to establish governance frameworks in the public interest—from <a href="https://ridefair.ca">Ridefair Toronto</a> to <a href="https://fairbnb.ca">Fairbnb Canada</a>, and more.',
                    links: [
                        {
                            label: 'Toronto moves towards reigning in Airbnb-style rentals',
                            url: 'https://www.cbc.ca/news/canada/toronto/toronto-regulations-airbnb-short-term-rentals-1.3813418',
                            source: 'CBC News',
                        },
                        {
                            label: 'Landlords lose appeal over Toronto’s new rules to limit Airbnb, other short-term rentals',
                            url: 'https://www.thestar.com/news/gta/2019/11/18/provincial-tribunal-upholds-toronto-bylaws-for-airbnb-other-short-term-rentals.html',
                            source: 'Toronto Star',
                        },
                        {
                            label: 'California just voted down stricter gig economy rules. Now, Toronto advocates are asking: do app companies have too much power?',
                            url: 'https://www.thestar.com/news/gta/2020/11/08/california-just-voted-down-stricter-gig-economy-rules-now-toronto-advocates-are-asking-do-app-companies-have-too-much-power.html',
                            source: 'Toronto Star',
                        },
                    ],
                },
                {
                    withBottomMargin: true,
                    type: 'text',
                    subtitle: 'Preserving Public Assets',
                    body: 'Sidewalk Labs, a Google affiliate, proposed an experimental “smart” city on Toronto’s prized waterfront, trying to privatize it in all but name. We supported a broad-based coalition which helped repudiate this type of plan, to ensure that future urban developments are driven by community needs.',
                    links: [
                        {
                            label: 'Opponents of Sidewalk Labs get advice from German tech protesters',
                            url: 'https://www.theglobeandmail.com/business/article-opponents-of-sidewalk-labs-get-advice-from-german-tech-protesters/',
                            source: 'The Globe and Mail',
                        },
                        {
                            label: '#Blocksidewalk says there’s a ‘power asymmetry’ between the city and Sidewalk Labs',
                            url: 'https://www.thestar.com/news/gta/2019/05/01/blocksidewalk-says-theres-a-power-asymmetry-between-the-city-and-sidewalk-labs.html',
                            source: 'Toronto Star',
                        },
                        {
                            label: 'Sidewalk Labs pulls out of Toronto smart city project after 3 years, citing ‘unprecedented economic uncertainty’',
                            url: 'https://www.theguardian.pe.ca/news/canada/sidewalk-labs-pulls-out-of-toronto-smart-city-project-after-3-years-citing-unprecedented-economic-uncertainty-446929/',
                            source: 'The Guardian (PEI)',
                        },
                    ],
                },
                {
                    type: 'text',
                    subtitle: 'Promoting Ethical Investments',
                    body: 'We worked with institutional investors and legislators to transform corporate governance among publicly traded REITs. We also help stakeholders form a common cause with investors to improve environmental, social, and corporate governance practices.',
                    links: [
                        {
                            label: 'Union fighting a boardroom battle against Newton firm',
                            url: 'https://www3.bostonglobe.com/business/talking-points/2017/06/23/union-fighting-boardroom-battle-against-newton-firm/ebVcuXgwkANWYEYunVD8pJ/story.html',
                            source: 'Boston Globe',
                        },
                        {
                            label: 'Their Eye on the Cycle, Shareholders Continue to Push REITs',
                            url: 'https://www.globest.com/sites/globest/2015/05/14/their-eye-on-the-cycle-shareholders-continue-to-push-reits/?slreturn=20210311143653',
                            source: 'GlobeST.com',
                        },
                        {
                            label: 'Airbnb IPO: The ESG Risks',
                            url: 'https://www.businessfast.co.uk/airbnb-ipo-the-esg-risks/',
                            source: 'Business Fast',
                        },
                    ],
                },
            ],
        },
        contact: {
            blocks: [
                {
                    type: 'text',
                    body: '<a href="mailto:info@metstrat.ca">info@metstrat.ca</a><br/><a href="tel:0016474098997">T +001 647 409 8997</a>',
                },
                { type: 'text', body: '' },
                {
                    type: 'text',
                    body: '50 Carroll Street<br/>Toronto, Ontario, Canada<br />M4M 3G3',
                },
            ],
        },
    },

    var: REACT_APP_VARIABLE,
};
