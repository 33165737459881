/* eslint-disable jsx-a11y/control-has-associated-label */

/* eslint-disable react/no-danger */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useResizeObserver } from '../../hooks/useObserver';
import useWindowSize from '../../hooks/useWindowSize';
import { pascalCase } from '../../lib/utils';

import {
    useOpenSection,
    useSetOpenSection,
    useCloseSections,
    useOpenSections,
} from '../contexts/SiteContext';
import Image from './Image';

import styles from '../../styles/partials/accordion.module.scss';

const propTypes = {
    name: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string),
    tagline: PropTypes.string,
    showTagline: PropTypes.bool,
    largeHeight: PropTypes.bool,
    largeWidth: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
};

const defaultProps = {
    images: [],
    tagline: null,
    showTagline: false,
    largeHeight: false,
    largeWidth: false,
    className: null,
    children: null,
};

const AccordionPartial = ({
    name,
    images,
    tagline,
    showTagline,
    largeHeight,
    largeWidth,
    className,
    children,
}) => {
    const { height: windowHeight = 0 } = useWindowSize();
    const scrollRef = useRef();
    const {
        ref: taglineRef,
        entry: { contentRect },
    } = useResizeObserver();
    const { height: contentTagHeight = 0 } = contentRect || {};

    // Sizing rules
    const taglineMaxHeight = contentTagHeight > 0 && showTagline ? contentTagHeight + 40 : 0;
    const withGapHeight = largeHeight ? windowHeight - 78 * 4 : windowHeight - 78 * 3;
    const openHeight = largeWidth ? '100%' : withGapHeight;

    const open = useOpenSection(name);
    const hasOpenSections = useOpenSections();
    const setOpenSection = useSetOpenSection();
    const closeSections = useCloseSections();

    const onClick = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpenSection(name, !open);
        },
        [name, open, setOpenSection],
    );

    const onClose = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            closeSections();
        },
        [name, open, setOpenSection],
    );

    useEffect(() => {
        if (scrollRef.current && scrollRef.current.scroll) {
            scrollRef.current.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    }, [open]);

    const title = useMemo(() => {
        switch (name) {
            case 'metstrat':
                return <FormattedMessage id="section.metstrat" defaultMessage="Section title" />;
            case 'campaigns':
                return <FormattedMessage id="section.campaigns" defaultMessage="Section title" />;
            case 'contact':
                return <FormattedMessage id="section.contact" defaultMessage="Section title" />;
            default:
                break;
        }
        return null;
    }, [name]);

    return (
        <section
            className={classNames([
                styles.container,
                {
                    [styles.open]: open,
                    [styles.closed]: !open,
                    [styles.showTagline]: showTagline,
                    [styles[`section${pascalCase(name)}`]]: true,
                    [className]: className !== null,
                },
            ])}
        >
            <header className={classNames([styles.header])}>
                <button
                    className={classNames([
                        styles.inner,
                        {
                            [styles[`section${pascalCase(name)}`]]: true,
                        },
                    ])}
                    type="button"
                    onClick={onClick}
                >
                    <div className={styles.top}>
                        {name === 'metstrat' ? (
                            <h1 className={styles.title}>{title}</h1>
                        ) : (
                            <h2 className={styles.title}>{title}</h2>
                        )}
                        <div
                            className={classNames([
                                styles.right,
                                {
                                    [styles[`section${pascalCase(name)}`]]: true,
                                },
                            ])}
                        >
                            <div className={styles.square} />
                        </div>
                    </div>
                </button>
            </header>
            <div className={styles.images}>
                {images.map((src, i) => (
                    <Image
                        className={styles.image}
                        key={`${src}-${i + 1}`}
                        src={src}
                        background
                        cover
                    />
                ))}
            </div>
            <div
                className={styles.children}
                ref={scrollRef}
                style={{ maxHeight: open ? openHeight : taglineMaxHeight }}
            >
                {tagline !== null ? (
                    <>
                        <div className={classNames([styles.tagline])} ref={taglineRef}>
                            {tagline}
                        </div>
                        <div className={styles.separator}>
                            <div className={styles.line} />
                        </div>
                    </>
                ) : null}
                <div className={styles.blocks}>
                    {tagline !== null ? (
                        <div className={styles.desktopTagline}>{tagline}</div>
                    ) : null}
                    {children}
                </div>
            </div>
            {hasOpenSections ? (
                <button className={classNames([styles.fill])} type="button" onClick={onClose} />
            ) : null}
        </section>
    );
};

AccordionPartial.propTypes = propTypes;
AccordionPartial.defaultProps = defaultProps;

export default AccordionPartial;
