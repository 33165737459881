import React from 'react';
import { createRoot } from 'react-dom/client';

import Root from './components/Root';
import data from './data/root';
import shouldPolyfill from './polyfills/should';

function getAppProps() {
    return data;
}

function renderApp(props) {
    const element = document.getElementById('app');
    const container = React.createElement(Root, props);
    const strictMode = React.createElement(React.StrictMode, {}, container);
    const root = createRoot(element);
    root.render(strictMode);
}

if (shouldPolyfill()) {
    import('./polyfills/index').then(() => renderApp(getAppProps()));
} else {
    renderApp(getAppProps());
}
