/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BioPartial from '../partials/Bio';

// import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/blocks/columns.module.scss';

const propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
};

const defaultProps = {
    items: [],
    className: null,
};

const ColumnsBlock = ({ items, className }) => {
    return (
        <div
            className={classNames({
                [styles.container]: true,
                [className]: className !== null,
            })}
        >
            {items.map((it) => (
                <div className={styles.column} key={`column-${it.name}`}>
                    <BioPartial {...it} />
                </div>
            ))}
        </div>
    );
};

ColumnsBlock.propTypes = propTypes;
ColumnsBlock.defaultProps = defaultProps;

export default ColumnsBlock;
