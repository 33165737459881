import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../styles/partials/footer-box.module.scss';

const propTypes = {
    email: PropTypes.string,
    phone: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    email: null,
    phone: null,
    className: null,
};

const FooterBox = ({ email, phone, className }) => (
    <footer className={classNames([styles.container, { [className]: className !== null }])}>
        {email !== null ? (
            <p>
                <a href={`mailto:${email}`}>{email}</a>
            </p>
        ) : null}
        {phone !== null ? (
            <p>
                <a href={`tel:${phone.replace(/\s/g, '')}`}>T +{phone}</a>
            </p>
        ) : null}
    </footer>
);

FooterBox.propTypes = propTypes;
FooterBox.defaultProps = defaultProps;

export default FooterBox;
