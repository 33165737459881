import PropTypes from 'prop-types';
import React, { useContext } from 'react';

const PagesContext = React.createContext(null);

export const usePagesCtx = () => useContext(PagesContext);

export const usePage = (page) => {
    const { pages } = usePagesCtx();
    return pages[page] || {};
};

const propTypes = {
    children: PropTypes.node.isRequired,
    pages: PropTypes.objectOf(PropTypes.object),
};

const defaultProps = {
    pages: {},
};

export const PagesProvider = ({ children, pages }) => (
    <PagesContext.Provider value={{ pages }}>{children}</PagesContext.Provider>
);

PagesProvider.propTypes = propTypes;
PagesProvider.defaultProps = defaultProps;

export default PagesContext;
