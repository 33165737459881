import isObject from 'lodash/isObject';

export const pascalCase = (str) =>
    str
        .replace(/[^a-z0-9]+/gi, ' ')
        .replace(/(\w)(\w*)/g, (g0, g1, g2) => `${g1.toUpperCase()}${g2.toLowerCase()}`)
        .replace(/\s+/gi, '');

export const isMessage = (message) => isObject(message) && typeof message.id !== 'undefined';

export const getComponentFromName = (name, components, defaultComponent = null) => {
    const componentName = pascalCase(name);
    return components[componentName] || defaultComponent;
};

export const addNonBreakingSpaces = (str) => str.replace(/&nbsp;/g, '\u00a0');
export const cleanExternalLinks = (str) =>
    str !== null
        ? str.replace(/(<a\\b[^<>]*href=['"]?http[^<>]+)>/gi, '$1 target="_blank">')
        : null;

export const replaceCharacters = (str) => {
    return str.replace(
        '***',
        '<img src="/img/text-end.svg" alt="end" style="width: 0.66em; height: 0.66em;" />',
    );
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
