import PropTypes from 'prop-types';
import React from 'react';
import { Route, Routes } from 'react-router';

import MainLayout from './layouts/Main';
import ErrorPage from './pages/Error';
import HomePage from './pages/Home';

import '../styles/styles.scss';

const propTypes = {
    routes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    pages: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const defaultProps = {};

const App = ({ routes, pages }) => (
    <Routes>
        <Route
            exact
            path={routes.home}
            element={
                <MainLayout full>
                    <HomePage page={pages.home} />
                </MainLayout>
            }
        />
        <Route
            path="*"
            element={
                <MainLayout>
                    <ErrorPage />
                </MainLayout>
            }
        />
    </Routes>
);

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
