import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { addNonBreakingSpaces } from '../../lib/utils';

import Html from '../partials/Html';
import Links from '../partials/Links';
import List from '../partials/List';

import styles from '../../styles/blocks/text.module.scss';

const propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    body: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line react/forbid-prop-types
    links: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line react/forbid-prop-types
    isLast: PropTypes.bool,
    withBottomMargin: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    title: null,
    subtitle: null,
    body: null,
    items: null,
    links: null,
    isLast: false,
    withBottomMargin: false,
    className: null,
};

const TextBlock = ({
    title,
    subtitle,
    body,
    items,
    links,
    isLast,
    withBottomMargin,
    className,
}) => (
    <div
        className={classNames({
            [styles.container]: true,
            [styles.isLast]: isLast,
            [styles.withBottomMargin]: withBottomMargin,
            [className]: className !== null,
        })}
    >
        <div className={styles.inner}>
            {title !== null ? (
                <h3
                    className={classNames([
                        styles.title,
                        {
                            [styles.withSubtitle]: subtitle !== null,
                        },
                    ])}
                >
                    {addNonBreakingSpaces(title)}
                </h3>
            ) : null}
            {subtitle !== null ? <h4 className={styles.subtitle}>{subtitle}</h4> : null}
            {body !== null ? <Html body={body} className={styles.paragraph} /> : null}
            {items !== null ? <List items={items} /> : null}
            {links !== null ? <Links items={links} /> : null}
        </div>
    </div>
);

TextBlock.propTypes = propTypes;
TextBlock.defaultProps = defaultProps;

export default TextBlock;
