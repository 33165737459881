/* eslint-disable jsx-a11y/control-has-associated-label */

/* eslint-disable react/no-danger */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import styles from '../../styles/partials/list.module.scss';

const propTypes = {
    items: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line react/forbid-prop-types
    className: PropTypes.string,
    children: PropTypes.node,
};

const defaultProps = {
    items: [],
    className: null,
    children: null,
};

const List = ({ items, className, children }) => {
    const elements = useMemo(
        () =>
            (items || [])
                .map((it) => {
                    const { type } = it || {};
                    if (type === 'subtitle') {
                        const { text = null } = it || {};
                        return text !== null ? <p className={styles.subtitle}>{text}</p> : null;
                    }
                    if (type === 'bullets') {
                        const { items: bullets = null } = it || {};
                        return items !== null ? (
                            <ul className={styles.bullets}>
                                {(bullets || []).map((text) => (
                                    <li className={styles.bullet}>{text}</li>
                                ))}
                            </ul>
                        ) : null;
                    }
                    return null;
                })
                .filter((it) => it !== null),
        [items],
    );
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {elements}
            {children}
        </div>
    );
};

List.propTypes = propTypes;
List.defaultProps = defaultProps;

export default List;
