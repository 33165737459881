import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import styles from '../../styles/pages/error.module.scss';

const propTypes = {};

const defaultProps = {};

const ErrorPage = () => (
    <div className={styles.container}>
        <div className={styles.inner}>
            <h1 className={styles.title}>
                <FormattedMessage
                    id="admin.error"
                    defaultMessage="Erreur"
                    description="Page title"
                />
            </h1>
            <div className={styles.actions}>
                <Link to="/" className="text-light">
                    <FormattedMessage
                        id="admin.back_home"
                        defaultMessage="Retourner à l'accueil"
                        description="Page description"
                    />
                </Link>
            </div>
        </div>
    </div>
);

ErrorPage.propTypes = propTypes;
ErrorPage.defaultProps = defaultProps;

export default ErrorPage;
