import PropTypes from 'prop-types';
import React from 'react';

import App from './App';
import { PagesProvider } from './contexts/PagesContext';
import { SiteProvider } from './contexts/SiteContext';

const propTypes = {
    routes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    pages: PropTypes.objectOf(PropTypes.object).isRequired, // eslint-disable-line react/forbid-prop-types
    sections: PropTypes.objectOf(PropTypes.object).isRequired, // eslint-disable-line react/forbid-prop-types
};

const defaultProps = {};

const Container = ({ routes, pages, sections }) => (
    <PagesProvider pages={{ ...pages }}>
        <SiteProvider sections={sections}>
            <App routes={routes} pages={pages} />
        </SiteProvider>
    </PagesProvider>
);

Container.propTypes = propTypes;
Container.defaultProps = defaultProps;

export default Container;
