import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Html from './Html';

import styles from '../../styles/partials/bio.module.scss';

const propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    body: PropTypes.string,
    linkedin: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    image: null,
    name: null,
    email: null,
    body: null,
    linkedin: null,
    className: null,
};

const Bio = ({ image, name, email, body, linkedin, className }) => (
    <div className={classNames([styles.container, { [className]: className !== null }])}>
        {image !== null ? (
            <img className={styles.image} src={image} alt={name || 'Partner'} />
        ) : null}
        {name !== null ? <p className={styles.name}>{name}</p> : null}
        {email !== null ? (
            <p className={styles.email}>
                <a href={`mailto:${email}`}>{email}</a>
            </p>
        ) : null}
        {body !== null ? <Html body={body} className={styles.body} /> : null}
        {linkedin !== null ? (
            <p className={styles.linkedin}>
                <a href={linkedin}>
                    <FormattedMessage id="bio.linkedin" defaultMessage="LinkedIn" />
                </a>
            </p>
        ) : null}
    </div>
);

Bio.propTypes = propTypes;
Bio.defaultProps = defaultProps;

export default Bio;
