import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/partials/image.module.scss';

const propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    credits: AppPropTypes.text,
    ratio: PropTypes.bool,
    cover: PropTypes.bool,
    background: PropTypes.bool,
    size: PropTypes.string,
    style: PropTypes.object, // eslint-disable-line
    backgroundStyle: PropTypes.object, // eslint-disable-line
    className: PropTypes.string,
    creditsClassName: PropTypes.string,
    creditsButtonClassName: PropTypes.string,
};

const defaultProps = {
    src: null,
    alt: null,
    credits: null,
    ratio: null,
    cover: false,
    background: false,
    size: null,
    style: null,
    backgroundStyle: {},
    className: null,
    creditsClassName: null,
    creditsButtonClassName: null,
};

const Image = ({
    src,
    alt,
    credits,
    ratio,
    cover,
    background,
    size,
    style,
    backgroundStyle,
    className,
    creditsClassName,
    creditsButtonClassName,
}) => {
    const [creditsOpen, setCreditsOpen] = useState(false);

    const onOpen = useCallback(() => {
        setCreditsOpen(true);
    }, [setCreditsOpen]);

    const onClose = useCallback(() => {
        setCreditsOpen(false);
    }, [setCreditsOpen]);

    // if (src === null) {
    //     return null;
    // }

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.isRatioed]: ratio,
                    [className]: className !== null,
                },
            ])}
            style={style}
        >
            {src === null ? <div className={styles.empty} /> : null}
            {src !== null && (ratio !== null || background) ? (
                <div
                    className={classNames([styles.backgroundImage, { [styles.cover]: cover }])}
                    style={{
                        backgroundImage: `url('${src}')`,
                        ...(ratio !== null
                            ? {
                                  height: 0,
                                  paddingBottom: `${ratio * 100}%`,
                                  backgroundSize: 'cover',
                              }
                            : null),
                        ...backgroundStyle,
                    }}
                />
            ) : null}
            {src !== null && ratio === null && !background ? (
                <img
                    className={classNames([styles.image])}
                    src={src}
                    alt={alt}
                    style={{ ...size }}
                />
            ) : null}
            {credits !== null ? (
                <button
                    className={classNames([
                        styles.button,
                        { [creditsButtonClassName]: creditsButtonClassName !== null },
                    ])}
                    onMouseEnter={onOpen}
                    onMouseLeave={onClose}
                    onFocus={onOpen}
                    onBlur={onClose}
                    type="button"
                >
                    <div className={styles.inner} />
                </button>
            ) : null}
            {credits !== null ? (
                <div
                    className={classNames([
                        styles.credits,
                        {
                            [creditsClassName]: creditsClassName !== null,
                            [styles.open]: creditsOpen,
                        },
                    ])}
                >
                    {credits}
                </div>
            ) : null}
        </div>
    );
};

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;

export default Image;
