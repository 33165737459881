/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';

import { useResizeObserver } from '../../hooks/useObserver';
import useWindowSize from '../../hooks/useWindowSize';
import { pascalCase } from '../../lib/utils';

import Blocks from '../blocks/Blocks';
import { useSections, useOpenSections, useOpenSection } from '../contexts/SiteContext';
import AccordionPartial from '../partials/Accordion';
import FooterBox from '../partials/FooterBox';
import Image from '../partials/Image';
import PageMeta from '../partials/PageMeta';

import styles from '../../styles/pages/home.module.scss';

import MobileFooter from '../../img/Mobile-Footer.jpg';

const propTypes = {
    page: PropTypes.object, // eslint-disable-line
};

const defaultProps = {
    page: null,
};

const HomePage = ({ page }) => {
    const imageRef = useRef(null);
    const { width: windowWidth = 0, height: windowHeight = 0 } = useWindowSize();
    const { sections = [], footer = null } = page || {};
    const { email, phone } = footer || {};
    const sectionData = useSections();
    const hasOpenSections = useOpenSections();
    const largeHeight = windowHeight >= 660;
    const largeWidth = windowWidth >= 790;
    const contactIsOpen = useOpenSection('contact');

    // Image sizing
    const {
        ref: innerRef,
        entry: { contentRect },
    } = useResizeObserver();

    useEffect(() => {
        if (windowHeight && contentRect && imageRef.current !== null) {
            const imageHeight = Math.max(Math.floor(windowHeight - contentRect.height), 0);
            imageRef.current.style.height = `${imageHeight}px`;
        }
    }, [contentRect, imageRef.current, windowHeight]);

    return (
        <div className={classNames([styles.container])}>
            <PageMeta {...page} />
            <div className={styles.background} />
            <div className={styles.inner} ref={innerRef}>
                {sections.map((name) => (
                    <AccordionPartial
                        key={name}
                        name={name}
                        tagline={sectionData[name].tagline || null}
                        images={sectionData[name].images || []}
                        showTagline={!hasOpenSections}
                        largeHeight={largeHeight}
                        largeWidth={largeWidth}
                    >
                        <Blocks
                            blockClassName={classNames([
                                styles.block,
                                {
                                    [styles[`block${pascalCase(name)}`]]: true,
                                },
                            ])}
                            blocks={sectionData[name].blocks || []}
                        />
                    </AccordionPartial>
                ))}
            </div>
            {!hasOpenSections || largeHeight || contactIsOpen ? (
                <div className={styles.mobileFooterContainer} ref={imageRef}>
                    <Image className={styles.mobileFooter} src={MobileFooter} />
                </div>
            ) : null}
            {footer !== null ? (
                <FooterBox
                    className={classNames([styles.desktopFooter])}
                    email={email}
                    phone={phone}
                />
            ) : null}
        </div>
    );
};

HomePage.propTypes = propTypes;
HomePage.defaultProps = defaultProps;

export default HomePage;
