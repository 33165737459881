/* eslint-disable react/jsx-props-no-spreading */
import { RoutesProvider } from '@folklore/routes';
import PropTypes from 'prop-types';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import Container from './Container';

const propTypes = {
    locale: PropTypes.string,
    messages: PropTypes.oneOfType([
        PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
        PropTypes.objectOf(PropTypes.string),
    ]),
    routes: PropTypes.objectOf(PropTypes.string),
    pages: PropTypes.objectOf(PropTypes.object), // eslint-disable-line react/forbid-prop-types
    sections: PropTypes.objectOf(PropTypes.object), // eslint-disable-line react/forbid-prop-types
};

const defaultProps = {
    locale: 'fr',
    messages: {},
    routes: {},
    pages: {},
    sections: {},
};

const Root = ({ locale, messages, routes, pages, sections }) => (
    <IntlProvider locale={locale} messages={messages[locale] || messages}>
        <BrowserRouter>
            <RoutesProvider routes={routes}>
                <Container routes={routes} pages={pages} sections={sections} />
            </RoutesProvider>
        </BrowserRouter>
    </IntlProvider>
);

Root.propTypes = propTypes;
Root.defaultProps = defaultProps;

export default Root;
