/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/blocks/image.module.scss';

const propTypes = {
    src: PropTypes.string,

    className: PropTypes.string,
};

const defaultProps = {
    src: null,
    className: null,
};

const ImageBlock = ({ src, className }) => {
    if (src === null) return null;
    const inner = <img className={styles.image} src={src} alt="Block" />;
    return (
        <div
            className={classNames({
                [styles.container]: true,
                [className]: className !== null,
            })}
        >
            {inner}
        </div>
    );
};

ImageBlock.propTypes = propTypes;
ImageBlock.defaultProps = defaultProps;

export default ImageBlock;
